import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const navLinkActiveStyles = {
  color: '  #0087d4',
  fontWeight: 600,
};

const navLinkHoverStyles = {
  color: '  #0087d4',
  fontWeight: 400,
};

const Header: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const {
    isOpen: aboutUsIsOpen,
    onOpen: aboutUsOnOpen,
    onClose: aboutUsOnClose,
  } = useDisclosure();
  const {
    isOpen: clientDeskIsOpen,
    onOpen: clientDeskOnOpen,
    onClose: clientDeskOnClose,
  } = useDisclosure();

  const [aboutToggle, setAboutToggle] = useState(false);
  const handleToggle = () => setAboutToggle(!aboutToggle);

  const [clientToggle, setClientToggle] = useState(false);
  const handleToggleClient = () => setClientToggle(!clientToggle);

  return (
    <>
      <Box
        className="header-wrap"
        bg={'#fff'}
        boxShadow={'1px 1px 4px #d4cfcf'}
        color={'#84868b'}
        fontWeight={500}
        fontFamily={'heading'}
        position={'fixed'}
        w={'100%'}
        zIndex={'3'}
        marginBottom={{ sm: '20%', md: '4%' }}>
        <Flex
          h={20}
          alignItems={'center'}
          justifyContent={'space-between'}
          padding={{ base: '0px 10px', md: '0px 20px', xl: '0px 50px' }}
          margin={'auto'}>
          <HStack
            as={'nav'}
            spacing={2}
            display={{ base: 'none', md: 'none', lg: 'flex' }}>
            <Box>
              <a href="/">
                <Image src={Logo} w={90} />
              </a>
            </Box>
          </HStack>
          <Flex className="mobile-nav-wrap" alignItems={'center'}>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              className={isOpen ? 'CloseIcon' : 'HamburgerIcon'}
              aria-label={'Open Menu'}
              display={{ lg: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <Flex className="logo-wrapper" display={{ lg: 'none' }}>
              <a href="/">
                <Image src={Logo} w={75} />
              </a>
            </Flex>
            <div className="empty-wrapper"></div>
          </Flex>
          <HStack
            className="chakra-menu__menu-list"
            spacing={8}
            alignItems={'center'}
            fontSize={'lg'}
            color={{ hover: 'red' }}>
            <HStack
              as={'nav'}
              spacing={2}
              display={{ base: 'none', md: 'none', lg: 'flex' }}
              justifyContent={{ base: 'none', md: 'space-between' }}>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={location.pathname === '/' ? navLinkActiveStyles : undefined}
                href={routes.landing}>
                Home
              </Link>
              <Menu>
                <MenuButton
                  sx={
                    location.pathname.includes('/about-us')
                      ? navLinkActiveStyles
                      : undefined
                  }>
                  About Us
                  <MdArrowDropDown color="#1970F1" size="25px" />
                </MenuButton>
                <MenuList
                  className="dropdown---menu-wrap"
                  onMouseEnter={aboutUsOnOpen}
                  onMouseLeave={aboutUsOnClose}>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.aboutRhythm)}>
                    About Rhythm
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.messageFromFounder)}>
                    Messages From Founder
                  </MenuItem>

                  {/* <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.organizationChart)}>
                    Organization Chart
                  </MenuItem> */}
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.ourTeam)}>
                    Our Team
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.selectionProcedure)}>
                    Selection Procedure
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.importantLicense)}>
                    Important License
                  </MenuItem>
                </MenuList>
              </Menu>
              {/* <Menu isOpen={aboutUsIsOpen}>
                <MenuButton
                  onMouseMove={aboutUsOnOpen}
                  sx={
                    location.pathname.includes('/about-us')
                      ? navLinkActiveStyles
                      : undefined
                  }>
                  About Us
                  <MdArrowDropDown color="#1970F1" size="25px" />
                </MenuButton>
                <MenuList
                  className="dropdown---menu-wrap"
                  onMouseEnter={aboutUsOnOpen}
                  onMouseLeave={aboutUsOnClose}>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.aboutRhythm)}>
                    About Rhythm
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.messageFromFounder)}>
                    Messages From Founder
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.ourTeam)}>
                    Our Team
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.selectionProcedure)}>
                    Selection Procedure
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.importantLicense)}>
                    Important License
                  </MenuItem>
                </MenuList>
              </Menu> */}
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/recruitment-procedure'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.recruitment}>
                Recruitment Procedure
              </Link>
              <Menu isOpen={clientDeskIsOpen}>
                <MenuButton
                  onMouseMove={clientDeskOnOpen}
                  onMouseLeave={clientDeskOnClose}>
                  {`Client's Desk`}
                  <MdArrowDropDown color="#1970F1" size="25px" />
                </MenuButton>
                <MenuList
                  className="dropdown---menu-wrap"
                  onMouseEnter={clientDeskOnOpen}
                  onMouseLeave={clientDeskOnClose}>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.client.form)}>
                    Client Form
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.client.login)}>
                    Client Login Process
                  </MenuItem>
                </MenuList>
              </Menu>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/candidate-form'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.candidateForm}>
                {`Candidate's Desk`}
              </Link>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/media'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.media}>
                {`Gallery`}
              </Link>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/blogs'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.blogs.index}>
                Blogs
              </Link>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/contact-us'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.contactUs}>
                {`Contact Us`}
              </Link>
            </HStack>
          </HStack>
        </Flex>
        {isOpen ? (
          <Box pb={4} display={{ lg: 'none' }} className="hambruger-wrapper">
            <Stack as={'nav'} spacing={4}>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={location.pathname === '/' ? navLinkActiveStyles : undefined}
                href={routes.landing}>
                Home
              </Link>
              <Menu>
                <MenuButton onClick={handleToggle} id="submenu_item">
                  About Us <MdArrowDropDown color="#1970F1" size="25px" />
                </MenuButton>
                <div
                  className={
                    aboutToggle ? 'submenu-item menu_active' : 'submenu-item '
                  }>
                  <MenuList>
                    <MenuItem
                      _hover={{
                        background: 'blue.300',
                      }}
                      onClick={() => navigate(routes.aboutUs.aboutRhythm)}>
                      About Rhythm
                    </MenuItem>
                    <MenuItem
                      _hover={{
                        background: 'blue.300',
                      }}
                      onClick={() =>
                        navigate(routes.aboutUs.messageFromFounder)
                      }>
                      Messages From Founder
                    </MenuItem>
                    {/* <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.whyRhythm)}>
                    Why Recruit from Rhythm
                  </MenuItem> */}
                    {/* <MenuItem
                    _hover={{
                      background: 'blue.300',
                    }}
                    onClick={() => navigate(routes.aboutUs.organizationChart)}>
                    Organization Chart
                  </MenuItem> */}
                    <MenuItem
                      _hover={{
                        background: 'blue.300',
                      }}
                      onClick={() => navigate(routes.aboutUs.ourTeam)}>
                      Our Team
                    </MenuItem>
                    <MenuItem
                      _hover={{
                        background: 'blue.300',
                      }}
                      onClick={() =>
                        navigate(routes.aboutUs.selectionProcedure)
                      }>
                      Selection Procedure
                    </MenuItem>
                    <MenuItem
                      _hover={{
                        background: 'blue.300',
                      }}
                      onClick={() => navigate(routes.aboutUs.importantLicense)}>
                      Important License
                    </MenuItem>
                  </MenuList>
                </div>
              </Menu>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/recruitment-procedure'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.recruitment}>
                Recruitment Procedure
              </Link>
              <Menu>
                <MenuButton onClick={handleToggleClient}>
                  {`Client's Desk`}{' '}
                  <MdArrowDropDown color="#1970F1" size="25px" />
                </MenuButton>
                <div
                  className={
                    clientToggle ? 'submenu-item menu_active' : 'submenu-item '
                  }>
                  <MenuList>
                    <MenuItem
                      _hover={{
                        background: 'blue.300',
                      }}
                      onClick={() => navigate(routes.client.form)}>
                      Client Form
                    </MenuItem>
                    <MenuItem
                      _hover={{
                        background: 'blue.300',
                      }}
                      onClick={() =>
                        navigate(routes.client.login, { replace: true })
                      }>
                      Client Login Process
                    </MenuItem>
                  </MenuList>
                </div>
              </Menu>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/candidate-form'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.candidateForm}>
                {`Candidate's Desk`}
              </Link>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/media'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.media}>
                {`Gallery`}
              </Link>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/blogs*'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.blogs.index}>
                {`Blogs`}
              </Link>
              <Link
                px={3}
                py={1}
                rounded={'md'}
                textDecoration={'none'}
                _hover={{ textDecoration: 'none', color: '#0087d4' }}
                sx={
                  location.pathname === '/contact-us'
                    ? navLinkActiveStyles
                    : undefined
                }
                href={routes.contactUs}>
                {`Contact Us`}
              </Link>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default Header;
