import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  IconProps,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FounderImg } from 'assets/images';
import React, { useEffect, useRef } from 'react';

const MessageFromFounder: React.FC = () => {
  const containerRef = useRef<any>();

  useEffect(() => {
    if (window.location.pathname.includes('/message-from-founder')) {
      window.scrollTo({
        behavior: 'smooth',
        top: containerRef?.current?.offsetTop,
      });
    }
  });

  return (
    <Container maxW={'8xl'} ref={containerRef}>
      {/* Message from Founder */}
      <Stack
        className="messagefromfounder-wrap"
        align={'flex-start'}
        spacing={{ base: 8, md: 10 }}
        // py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                bg: 'red.400',
                zIndex: -1,
              }}>
              Messages From
            </Text>
            <br />
            <Text as={'span'} color={'red.400'}>
              Founder
            </Text>
          </Heading>
          <Text
            color={'gray.500'}
            textAlign={'justify'}
            padding={'0px 30px 0px 10px'}>
            {`Yes of course there are many challenges laid in the way, yet if anyone can trust without known, is the Gorkha, 
            the true man in nature and belief.

            The trend of hiring Nepalese workers by the foreign companies, especially the Gulf countries has not only benefited the companies and the 
            workers in terms of remittance but also helped to further enhance the bilateral relationship between the two contries.

            The Rhythm Oversease Pvt. Ltd., Nepal is one among the several manpower supplying aency in Nepal to proce to be one of the most reliable recruitment companies since its establishment in 2010.
            , not with standing the hindrances coming its way.

            In the last four years, the Rhythm Oversease Pvt. Ltd. has maintained its prominence in this field with the objective of supplying right man at the right place.

            `}
            <br />
            {`With warm regards,`}
            <br />
            {`Dhruba Paudel`}
            <br />
            {`Founder `}
            <br />
            {`Rhythm Oversease Pvt. Ltd.`}
          </Text>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Blob
            w={'150%'}
            h={'150%'}
            position={'absolute'}
            top={'-20%'}
            left={0}
            zIndex={-1}
            color={useColorModeValue('red.50', 'red.400')}
          />
          <Box
            position={'relative'}
            height={'300px'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={'full'}
            overflow={'hidden'}>
            <Image
              alt={'Hero Image'}
              fit={'cover'}
              align={'center'}
              w={'100%'}
              h={'100%'}
              src={FounderImg}
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
};

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default MessageFromFounder;
