import { amritChaulagain, manisMani, shanUpadhyaya, sundarKc, vinodPoudel } from "assets/images";

export const ourTeamData = [
  {
    name: 'Vinod Paudel',
    designation: 'Co-Founder / Chairman',
    image: vinodPoudel,
    description: "Vinod Paudel, Co-Founder / Chairman of Rhythm Overseas Pvt. Ltd, began his journey in the recruitment industry in June 2004. His exceptional leadership skills and steadfast determination to deliver top-notch services have earned Rhythm Overseas a reputation as one of Nepal&apos;s most trusted manpower recruitment agencies. Paudel&apos;s belief in quality service has solidified long-lasting relationships with clients around the world. His passion for the industry and relentless drive to succeed make him a valuable asset to the company and its clients."
  }, {
    name: 'Manish Mani',
    designation: 'Managing Director (MD)',
    image: manisMani,
    description: " Manish Mani, Managing Director, drives company growth by identifying opportunities, forging partnerships, and leveraging expertise in sales and marketing. With a focus on market trends and consumer behavior, he ensures revenue growth and maintains the company&aposs competitive edge. His innovative strategies and leadership contribute to long-term objectives and industry leadership."
  },
  {
    name: 'Sundar KC',
    designation: 'Director',
    image: sundarKc,
    description: "Sunder KC, the Director of Rhythm Overseas Pvt. Ltd, has a multi-disciplinary background in business and enjoys a strong reputation within the business community in Nepal. He owns bot a cooperative bank and a travel agency, with his primary focus being the recruitment of young individuals from remote regions of the Himalayas. His efforts in this sector are highly respected. His gracious and polite demeanour has earned him admiration from his team, and his effective management style is seen as a model in the industry. He places a strong emphasis on timely deliveries to establish and maintain strong relationships with clients."
  },
]